<template>
  <div class="layout_common remote_handle">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <salesArea
              v-if="isShowSaleArea"
              :hval="salesArea"
              @change="salesAreaChange"
              @saleArea="saleArea"
            />
            <selectComp
              v-if="!isVisit && !myVisit"
              :hval="status"
              :data="statusList"
              @change="statusChange"
              placeholder="工单状态"
            />
            <inputComp :hval="code" @blur="codeBlur" placeholder="工单编号" />
            <inputComp
              :hval="orderCode"
              @blur="orderCodeBlur"
              placeholder="订单编号"
            />
            <inputComp
              inputType="number"
              :hval="repairCode"
              @blur="repairCodeBlur"
              placeholder="报修号"
            />
            <inputComp
              :hval="registrantContacterMobile"
              @blur="registrantContacterMobileBlur"
              placeholder="注册手机号"
            />
            <inputComp
              :hval="mobile"
              @blur="mobileBlur"
              placeholder="报修人手机号"
            />
            <inputComp
              :hval="repairContacter"
              @blur="repairContacterBlur"
              placeholder="报修人"
            />
            <merchant :hval="storeName" @storeNameChange="storeNameChange" />
            <selectComp
              placeholder="报修来源"
              :hval="source"
              :data="sourceList"
              @change="sourceChange"
            />
            <handleUser
              placeholder="售后处理人"
              :hval="remoteId"
              businessRole="aftermarket"
              @change="remoteIdChange"
            />
            <handleUser
              placeholder="客服处理人"
              :hval="serviceId"
              businessRole="service"
              @change="serviceIdChange"
            />
            <handleUser
              placeholder="调度处理人"
              :hval="dispatchId"
              businessRole="dispatch"
              @change="dispatchIdChange"
            />
            <selectComp
              :hval="secendOrder"
              :data="secendOrderList"
              @change="secendOrderChange"
              placeholder="是否二次处理"
            />
            <selectComp
              :hval="premiumProcessing"
              :data="premiumProcessingList"
              @change="premiumProcessingChange"
              placeholder="是否紧急"
            />
            <selectComp
              :hval="urge"
              :data="complaintList"
              @change="urgeChange"
              placeholder="是否催促"
            />
            <selectComp
              :hval="poorUnpacking"
              :data="poorUnpackingList"
              @change="poorUnpackingChange"
              placeholder="是否开箱不良"
            />
            <selectComp
              :hval="complaint"
              :data="complaintList"
              @change="complaintChange"
              placeholder="是否投诉"
            />
            <!--  响应时效、处理时效 客服回访和我的回访页面不需要这两个时效  增加回访时效-->
            <selectComp
              v-if="!isVisit && !myVisit"
              :hval="responsePrescription"
              :data="responseAgingList"
              @change="responsePrescriptionChange"
              placeholder="响应时效"
            />
            <selectComp
              v-if="!isVisit && !myVisit"
              :hval="handlePrescription"
              :data="handleAgingList"
              @change="handlePrescriptionChange"
              placeholder="处理时效"
            />
            <!-- 客服回访和我的回访页面 增加回访时效 -->
            <selectComp
              v-if="isVisit || myVisit"
              :hval="visitPrescription"
              :data="visitAgingListList"
              @change="visitPrescriptionChange"
              placeholder="回访时效"
            />
            <selectComp
              v-if="isVisit"
              :hval="assignService"
              :data="assignServiceList"
              @change="assignServiceChange"
              placeholder="回访人状态"
            />
            <handleUser
              v-if="isVisit || pageType === 'history'"
              placeholder="回访客服"
              :hval="visitServiceId"
              businessRole="service"
              @change="visitServiceIdChange"
            />
            <!-- 客服回访页面显示 -->
            <template v-if="isVisit">
              <selectComp
                :hval="treatmentMethod"
                :data="treatmentMethodList"
                :optionsParams="{ value: 'code', label: 'name' }"
                @change="treatmentMethodChange"
                placeholder="处理方式"
              />
              <selectComp
                :hval="ownershipResponsibility"
                :data="ownershipResponsibilityList"
                :optionsParams="{ value: 'code', label: 'name' }"
                @change="ownershipResponsibilityChange"
                placeholder="责任归属"
              />
            </template>
            <!-- 业务类型、工单分类 -->
            <selectComp
              :data="orderTypeList"
              :hval="orderType"
              @change="orderTypeChange"
              placeholder="业务类型"
            />
            <selectComp
              :data="orderTypeChiList"
              :hval="orderTypeChi"
              @change="orderTypeChiChange"
              placeholder="工单类型(要先选业务类型)"
            />
            <selectComp
              :data="guestSentimentList"
              :hval="guestSentiment"
              @change="guestSentimentChange"
              placeholder="是否客情单"
            />
            <dateComp :hval="date" @change="dateChange" />
            <btnGroup @search="search" @reset="reset" />
            <btnComp
              v-permission="'批量指派'"
              v-if="isVisit"
              :disabled="allotDisabled"
              btnType="plain"
              @click="batchVisit"
              >批量指派</btnComp
            >
            <btnComp
              v-permission="'批量无需回访'"
              v-if="isVisit"
              :disabled="allotDisabled"
              btnType="plain"
              @click="batchNoVisit"
              >批量无需回访</btnComp
            >
            <btnComp @click="exportData('device')" v-permission="'导出设备'"
              >导出设备</btnComp
            ><btnComp
              @click="exportData('devideToOrder')"
              v-permission="'导出设备对应的工单'"
              >导出设备对应的工单</btnComp
            >
            <btnComp @click="orderExportData" v-permission="'导出工单'"
              >导出工单</btnComp
            >
          </div>
        </div>
        <tableComp
          v-loading="loading"
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          :isShowCheckBox="isVisit"
          @select="select"
          @selectAll="selectAll"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="statusName" label="工单状态" width="170">
            <template v-slot="scope">
              <div>{{ scope.row.statusName }}</div>
              <div v-if="scope.row.orderTypeName">
                [{{ scope.row.orderTypeName }}/{{ scope.row.orderTypeChiName }}]
              </div>
              <div style="color:#ff6700" v-if="scope.row.repairCode">
                [报修号:{{ scope.row.repairCode }}]
              </div>
            </template>
          </el-table-column>
          <el-table-column slot="repairContacter" label="报修人">
            <template v-slot="scope">
              <div>{{ scope.row.repairContacter }}</div>
              <img
                v-if="scope.row.customerLabel === 'VIP'"
                src="~@/assets/images/vip.png"
                alt=""
                width="20"
              />
            </template>
          </el-table-column>
          <el-table-column slot="code" label="工单编号" min-width="200">
            <template v-slot="scope">
              <div
                v-permission="'详情'"
                style="color:#385BFF;cursor:pointer"
                @click="codeClick(scope.row, 'code-btn')"
              >
                {{ scope.row.code }}
                <div style="color:#FF7F00">{{ scope.row.remark }}</div>
              </div>
              <div v-if="!flag">
                {{ scope.row.code }}
                <div style="color:#FF7F00">{{ scope.row.remark }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            slot="premiumProcessing"
            label="置顶/紧急/投诉/催促/开箱不良/二次处理/客情单"
            width="160"
          >
            <template v-slot="scope">
              <span :style="{ color: scope.row.top ? 'red' : '' }">{{
                scope.row.top ? '顶' : '-'
              }}</span>
              /
              <span
                :style="{ color: scope.row.premiumProcessing ? 'red' : '' }"
                >{{ scope.row.premiumProcessing ? '急' : '-' }}</span
              >
              /
              <span :style="{ color: scope.row.complaint ? 'red' : '' }">{{
                scope.row.complaint ? '诉' : '-'
              }}</span>
              /
              <span :style="{ color: scope.row.urgeNum ? 'red' : '' }">{{
                scope.row.urgeNum ? scope.row.urgeNum + '催' : '-'
              }}</span>
              /
              <span :style="{ color: scope.row.poorUnpacking ? 'red' : '' }">{{
                scope.row.poorUnpacking ? '开箱不良' : '-'
              }}</span>
              /
              <span :style="{ color: scope.row.firstOrder ? 'red' : '' }">{{
                scope.row.firstOrder ? '二次处理' : '-'
              }}</span>
              /
              <span :style="{ color: scope.row.guestSentiment ? 'red' : '' }">{{
                scope.row.guestSentiment ? '客情单' : '-'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column slot="handlerInfo" label="处理人" width="140">
            <template v-slot="scope">
              <ul>
                <li v-if="scope.row.serviceName">
                  客服:{{ scope.row.serviceName }}
                </li>
                <li v-if="scope.row.remoteName">
                  售后:{{
                    scope.row.remoteName +
                      (scope.row.remoteNum > 1
                        ? '/' + scope.row.remoteNum
                        : '') || '-'
                  }}
                </li>
                <li v-if="scope.row.storeHandleName">
                  商家:{{
                    scope.row.storeHandleName +
                      (scope.row.storeNum > 1
                        ? '/' + scope.row.storeNum
                        : '') || '-'
                  }}
                </li>
                <li v-if="scope.row.dispatchName">
                  调度:{{ scope.row.dispatchName }}
                </li>
                <li v-if="scope.row.visitNum">上门:{{ scope.row.visitNum }}</li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column
            slot="expressInfo"
            label="用户已收/待发(快递)"
            width="120"
          >
            <template v-slot="scope">
              <span>{{
                scope.row.finishConsignment + '/' + scope.row.notConsignment
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!isVisit && !myVisit"
            slot="createdTime"
            label="工单时效"
            width="220"
          >
            <template v-slot="scope">
              <div>创建:{{ scope.row.createdTime.slice(2, -3) }}</div>
              <div>排班:{{ scope.row.appointmentTime.slice(2, -3) }}</div>
              <div>
                <span
                  :style="{
                    color: scope.row.responsePrescription ? '' : '#385BFF'
                  }"
                  >{{
                    scope.row.responsePrescription ? '已响应:' : '待响应:'
                  }}</span
                >
                <span
                  :style="{
                    color: scope.row.responseDuration <= 30 * 60 ? '' : 'red'
                  }"
                  >{{ scope.row.responseDurationStr }}</span
                >
              </div>
              <div>
                <span
                  :style="{
                    color: scope.row.handlePrescription ? '' : '#385BFF'
                  }"
                  >{{
                    scope.row.handlePrescription ? '已处理:' : '待处理:'
                  }}</span
                >
                <span
                  :style="{
                    color: scope.row.handleDuration <= 4 * 60 * 60 ? '' : 'red'
                  }"
                  >{{ scope.row.handleDurationStr }}</span
                >
              </div>
            </template>
          </el-table-column>
          <!-- 回访时效 -->
          <el-table-column
            v-if="isVisit || myVisit"
            slot="visitAging"
            label="回访时效"
            width="200"
          >
            <template v-slot="scope">
              <div
                :style="{
                  color: scope.row.visitDuration <= 48 * 60 * 60 ? '' : 'red'
                }"
              >
                {{ scope.row.visitDurationStr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            slot="operate"
            label="操作"
            fixed="right"
            width="170"
          >
            <template v-slot="scope">
              <btnComp
                size="mini"
                @click="receive(scope.row)"
                v-if="scope.row.status >= 10 && scope.row.status <= 40"
                v-permission="'领取'"
                >领取</btnComp
              >
              <!-- 只有个人中心的跟进工单才显示置顶和取消置顶 -->
              <btnComp
                style="margin-top:10px"
                v-if="pageType === 'follow' && dataAuthority && !myVisit"
                size="mini"
                :btnType="scope.row.top ? 'info' : 'warning'"
                @click="top(scope.row)"
                >{{ scope.row.top ? '取消置顶' : '置顶' }}</btnComp
              >
              <div class="flex" style="margin-top:10px">
                <!--跟进工单页面取消状态删除操作 -->
                <btnComp
                  size="mini"
                  btnType="danger"
                  v-if="scope.row.status === 10001 && pageType === 'follow'"
                  @click="over(scope.row)"
                  v-permission="'删除'"
                  >删除</btnComp
                >
                <!--历史工单页面删除状态恢复操作 -->
                <btnComp
                  size="mini"
                  v-if="
                    scope.row.status === 10002 ||
                      scope.row.status === 10001 ||
                      scope.row.status === 1
                  "
                  @click="restore(scope.row)"
                  v-permission="'恢复'"
                  >恢复</btnComp
                >
              </div>
              <!-- 客服回访页面领取 -->
              <btnComp
                size="mini"
                @click="receiverToVist(scope.row)"
                v-if="isVisit && !scope.row.visitServiceName"
                v-permission="'领取'"
                >领取</btnComp
              >
              <!-- 个人中心-我的回访 -->
              <div
                style="color:#385BFF;cursor:pointer"
                v-permission="'回访'"
                v-if="myVisit"
                @click="codeClick(scope.row)"
              >
                回访
              </div>
              <btnComp
                v-permission="'二次售后'"
                btnType="text"
                @click="secondScenceBtn(scope.row)"
                >二次售后</btnComp
              >
              <btnComp
                v-if="pageType !== 'history'"
                v-permission="'进入聊天'"
                btnType="text"
                @click="codeClick(scope.row, 'chat-btn')"
                >进入聊天</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <!-- 批量回访 -->
    <batchVisit
      :isShow="batchVisitShow"
      :selectIds="selectIds"
      @close="close"
    />
    <!-- 历史工单删除恢复 -->
    <restore :isShow="restoreShow" @close="close" :info="info" />
    <!-- 详情弹窗 -->
    <drawerDetail @close="close" />
    <!-- 二次上门(借用订单系统中的新增售后弹窗)-->
    <secondScene
      :isShow="secondSceneShow"
      @close="close"
      :secondScenceOrderId="secondScenceOrderId"
      dialogType="secondScene"
    />
    <!-- 提示 -->
    <dialogComp title="提示" :isShow="hintShow" @close="close" @sure="sureOver">
      {{ `确定要${hintCon}吗？` }}
    </dialogComp>
  </div>
</template>
<script>
import handleUser from '@/views/components/handleUser'
import merchant from '@/views/components/merchant'
import salesArea from '@/views/components/salesArea'
import drawerDetail from '@/views/work-order/all-order/detail'
import secondScene from '@/views/order-system/order-list/add'
import batchVisit from './dialog/batchVisit'
import restore from './dialog/restore'
import {
  workOrderList,
  workOrderExport,
  deviceToOrderExport,
  followOrderExport,
  historyOrderExport,
  workOrderUpdateStatus,
  workOrderListToHistory,
  receiverToVist,
  workOrderCancel,
  workOrderTop,
  batchNoVisit,
  dictThree
} from '@/api'
import { btnPermission } from '@/utils/directive'
import {
  handleAgingList,
  visitAgingListList,
  responseAgingList,
  assignServiceList,
  orderTypeList,
  orderTypeChiList1,
  orderTypeChiList2,
  orderTypeChiList3,
  sourceList
} from '@/utils/constData'
import { mapMutations } from 'vuex'
// import chatMixin from '@/mixin/chat-mixin'
export default {
  name: 'all-order',
  // mixins: [chatMixin],
  components: {
    handleUser,
    batchVisit,
    merchant,
    restore,
    drawerDetail,
    secondScene,
    salesArea
  },
  props: {
    // 不同组件显示不同状态列表
    // 1客服处理 2售后处理  3商家处理  4 调度处理  5跟进工单(个人中心和工单管理) 6 历史工单(个人中心和工单管理)
    listType: {
      type: [Number, null],
      default: null
    },
    // 是否加数据权限  我的工单传true
    dataAuthority: {
      type: Boolean,
      default: false
    },
    // 是否是跟进工单 follow、历史工单 history
    pageType: {
      type: String,
      default: 'follow'
    },
    // 是否客服回访页面
    isVisit: {
      type: Boolean,
      default: false
    },
    // 是否我的回访页面
    myVisit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      info: {},
      selectIds: [],
      batchVisitShow: false,
      restoreShow: false,
      hintShow: false,
      secondSceneShow: false,
      secondScenceOrderId: '',
      hintCon: '',
      curPage: 1,
      pageSize: 10,
      total: 0,
      status: '',
      code: '',
      orderCode: '',
      repairCode: null,
      mobile: '',
      repairContacter: '',
      registrantContacterMobile: '',
      storeId: '',
      storeName: '',
      remoteId: '',
      serviceId: '',
      startTime: '',
      endTime: '',
      poorUnpacking: null,
      premiumProcessing: null,
      secendOrder: null,
      urge: null,
      complaint: null,
      responsePrescription: null,
      handlePrescription: null,
      visitPrescription: null,
      assignService: null,
      visitServiceId: '',
      dispatchId: '',
      date: [],
      level: '',
      cityId: '',
      salesArea: [],
      ownershipResponsibility: '',
      ownershipResponsibilityList: [],
      treatmentMethod: '',
      treatmentMethodList: [],
      guestSentiment: '',
      orderType: '',
      orderTypeChi: '',
      orderTypeChiList: [],
      source: '',
      tableData: [],
      premiumProcessingList: [
        { label: '正常', value: 0 },
        { label: '紧急', value: 1 }
      ],
      poorUnpackingList: [
        { label: '正常', value: false },
        { label: '不良', value: true }
      ],
      complaintList: [
        { label: '否', value: false },
        { label: '是', value: true }
      ],
      secendOrderList: [
        { label: '是', value: true },
        { label: '否', value: false }
      ],
      guestSentimentList: [
        { label: '是', value: true },
        { label: '否', value: false }
      ]
    }
  },
  computed: {
    theadName () {
      return this.handleTheadName()
    },
    allotDisabled () {
      return !(this.selectIds.length > 0)
    },
    statusList () {
      return this.handleStatus(this.listType)
    },
    // 跟进工单、上门处理、历史工单、销售查单显示战区
    isShowSaleArea () {
      const { path } = this.$route
      return path === '/work-order/follow' || path === '/work-order/history'
    },
    // 来判断是否有详情权限
    flag () {
      return btnPermission(this.$store.state.menuList, this.$route.path, '详情')
    },
    // 定义的常量转换
    responseAgingList () {
      return responseAgingList
    },
    handleAgingList () {
      return handleAgingList
    },
    assignServiceList () {
      return assignServiceList
    },
    visitAgingListList () {
      return visitAgingListList
    },
    orderTypeList () {
      return orderTypeList
    },
    orderTypeChiList1 () {
      return orderTypeChiList1
    },
    orderTypeChiList2 () {
      return orderTypeChiList2
    },
    orderTypeChiList3 () {
      return orderTypeChiList3
    },
    sourceList () {
      return sourceList
    }
  },
  mounted () {
    this.getSelectData(101024, 'ownershipResponsibilityList')
    this.getSelectData(101021, 'treatmentMethodList')
    // 相关工单tab跳转过来 自动查询对应的工单
    this.code = this.$route.query.code
    // 如果是历史工单  默认是已完结 1000
    this.status = this.pageType === 'history' ? 1000 : ''
    // 没有战区的在mounted请求列表   有战区的在子组件传过来之后在请求
    !this.isShowSaleArea && this.getList()
  },
  methods: {
    ...mapMutations(['SET_DRAWER_SHOW', 'SET_INDEX_INFO']),
    // =======================================================
    // 获取展开后  多个下拉列表 问题归类\原因分析\处理方式
    async getSelectData (code, data) {
      const params = {
        code
      }
      const res = await dictThree(params)
      this[data] = res.data
    },
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.initSalesArea = salesArea
      this.initLevel = level
      this.initCityId = cityId
      this.level = level
      this.cityId = cityId
      this.salesArea = salesArea
      this.getList()
    },
    // 处理表格头动态显示
    handleTheadName () {
      if (this.isVisit) {
        return [
          // { prop: 'statusName', label: '工单状态', width: 100 },
          { slot: 'statusName' },
          { slot: 'premiumProcessing' },
          { slot: 'code' },
          { prop: 'customerName', label: '客户', width: 220 },
          { prop: 'repairContacter', label: '报修人', width: 100 },
          { slot: 'visitAging' },
          { slot: 'handlerInfo' },
          { prop: 'visitServiceName', label: '回访客服', width: 100 },
          { slot: 'expressInfo' },
          { slot: 'operate' }
        ]
      } else if (this.myVisit) {
        return [
          { slot: 'expressInfo' },
          { slot: 'statusName' },
          // { prop: 'statusName', label: '工单状态', width: 100 },
          { slot: 'premiumProcessing' },
          { slot: 'code' },
          { prop: 'storeName', label: '订单商家' },
          { slot: 'repairContacter' },
          // { prop: 'repairContacter', label: '报修人', width: 100 },
          { slot: 'visitAging' },
          { slot: 'handlerInfo' },
          { prop: 'visitServiceName', label: '回访客服' },
          { slot: 'operate' }
        ]
      } else {
        return [
          { slot: 'expressInfo' },
          { slot: 'statusName' },
          // { prop: 'statusName', label: '工单状态', width: 100 },
          { slot: 'premiumProcessing' },
          { slot: 'code' },
          { prop: 'storeName', label: '订单商家' },
          { slot: 'repairContacter' },
          // { prop: 'repairContacter', label: '报修人', width: 100 },
          { slot: 'createdTime' },
          { slot: 'handlerInfo' },
          { slot: 'operate' }
        ]
      }
    },
    handleStatus (type) {
      switch (type) {
        // 1客服处理 2售后处理  3商家处理  4 调度处理  5跟进工单(个人中心和工单管理) 6 历史工单(个人中心和工单管理)
        case 1:
          return [
            { label: '待客服领取', value: 10 },
            { label: '客服跟进中', value: 50 }
          ]
        case 2:
          return [
            { label: '待售后领取', value: 20 },
            { label: '售后处理中', value: 60 }
          ]
        case 3:
          return [
            { label: '待商家领取', value: 30 },
            { label: '商家处理中', value: 70 }
          ]
        case 4:
          return [
            { label: '待调度领取', value: 40 },
            { label: '调度处理中', value: 80 }
          ]
        case 5:
          // 根据登录用户的角色,显示对应的个人中心的跟进工单的状态
          // eslint-disable-next-line no-case-declarations
          const businessRoleArr = localStorage
            .getItem('businessRole')
            .split(',')
          if (
            businessRoleArr.length === 1 &&
            this.$route.path === '/user-center/follow'
          ) {
            switch (businessRoleArr[0]) {
              case 'service':
                return [
                  { label: '待客服领取', value: 10 },
                  { label: '客服跟进中', value: 50 },
                  { label: '取消', value: 10001 }
                ]
              case 'aftermarket':
                return [
                  { label: '待售后领取', value: 20 },
                  { label: '售后处理中', value: 60 },
                  { label: '取消', value: 10001 }
                ]
              case 'dispatch':
                return [
                  { label: '待调度领取', value: 40 },
                  { label: '调度处理中', value: 80 },
                  { label: '取消', value: 10001 }
                ]
              case 'store':
                return [
                  { label: '待商家领取', value: 30 },
                  { label: '商家处理中', value: 70 },
                  { label: '取消', value: 10001 }
                ]
            }
          } else {
            return [
              { label: '待客服领取', value: 10 },
              { label: '待售后领取', value: 20 },
              { label: '待商家领取', value: 30 },
              { label: '待调度领取', value: 40 },
              { label: '客服跟进中', value: 50 },
              { label: '售后处理中', value: 60 },
              { label: '商家处理中', value: 70 },
              { label: '调度处理中', value: 80 },
              { label: '取消', value: 10001 }
            ]
          }
          break
        case 6:
          return [
            { label: '已完结', value: 1000 },
            { label: '删除', value: 10002 }
          ]
        default:
          return [
            { label: '待客服领取', value: 10 },
            { label: '待售后领取', value: 20 },
            { label: '待商家领取', value: 30 },
            { label: '待调度领取', value: 40 },
            { label: '客服跟进中', value: 50 },
            { label: '售后处理中', value: 60 },
            { label: '商家处理中', value: 70 },
            { label: '调度处理中', value: 80 },
            { label: '处理完成', value: 90 },
            { label: '待客服回访', value: 100 },
            { label: '已完结', value: 1000 },
            { label: '取消', value: 10001 }
          ]
      }
    },
    getList () {
      // 跟进工单 历史工单 客服回访 我的回访（ 只显示 待回访状态 页面显示同跟进工单） 三个页面status不同
      let newStatus
      if (this.isVisit || this.myVisit) {
        newStatus = 100
      } else {
        newStatus = this.status
      }
      this.params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        status: newStatus,
        code: this.code,
        orderCode: this.orderCode,
        repairCode: this.repairCode,
        mobile: this.mobile,
        repairContacter: this.repairContacter,
        registrantContacterMobile: this.registrantContacterMobile,
        storeId: this.storeId,
        remoteId: this.remoteId,
        serviceId: this.serviceId,
        startTime: this.startTime,
        endTime: this.endTime,
        type: this.listType,
        dataAuthority: this.dataAuthority,
        poorUnpacking: this.poorUnpacking,
        premiumProcessing: this.premiumProcessing,
        secendOrder: this.secendOrder,
        complaint: this.complaint,
        urge: this.urge,
        responsePrescription: this.responsePrescription,
        handlePrescription: this.handlePrescription,
        visitPrescription: this.visitPrescription,
        assignService: this.assignService,
        visitServiceId: this.visitServiceId,
        dispatchId: this.dispatchId,
        level: this.level,
        cityId: this.cityId,
        ownershipResponsibility: this.ownershipResponsibility,
        treatmentMethod: this.treatmentMethod,
        orderType: this.orderType,
        orderTypeChi: this.orderTypeChi,
        guestSentiment: this.guestSentiment,
        source: this.source
      }
      // 根据是跟进工单、历史工单请求不同的接口
      this.loading = true
      if (this.pageType === 'follow' || this.isVisit || this.myVisit) {
        workOrderList(this.params).then(res => {
          this.loading = false
          this.tableData = res.data.list
          this.total = res.data.totalCount
        })
      } else if (this.pageType === 'history') {
        workOrderListToHistory(this.params).then(res => {
          this.loading = false
          this.tableData = res.data.list
          this.total = res.data.totalCount
        })
      }
    },
    // 点击工单号弹窗
    codeClick (info, clickBtnType) {
      // clickBtnType: '', // 用于区分点的是哪个按钮进入详情
      if (clickBtnType === 'chat-btn') {
        // 点击进入聊天 先验证是否请求成功，请求成功再弹窗
        this.$store
          .dispatch('im/enterChatAction', info.id)
          .then(res => {
            this.SET_DRAWER_SHOW(true)
          })
          .catch(_ => {
            this.SET_DRAWER_SHOW(false)
          })
      } else {
        this.SET_DRAWER_SHOW(true)
      }
      const { id, categoryId, orderCode } = info
      // this.SET_DRAWER_SHOW(true)
      this.SET_INDEX_INFO({ id, categoryId, orderCode, clickBtnType })
    },
    // 二次上门
    secondScenceBtn ({ id }) {
      this.secondScenceOrderId = id
      this.secondSceneShow = true
    },
    // 批量指派
    batchVisit () {
      this.batchVisitShow = true
    },
    // 批量无需回访
    batchNoVisit () {
      this.hintShow = true
      this.hintCon = '批量无需回访'
    },
    // 导出
    exportData (type) {
      type === 'device'
        ? workOrderExport(this.params)
        : deviceToOrderExport(this.params)
      this.$message({
        message:
          '已经发起导出任务，请关注个人中心导出任务列表页面，任务完成后可以下载导出文档',
        type: 'warning',
        duration: 5000
      })
    },
    orderExportData () {
      this.pageType === 'history'
        ? historyOrderExport(this.params)
        : followOrderExport(this.params)
      this.$message({
        message:
          '已经发起导出任务，请关注个人中心导出任务列表页面，任务完成后可以下载导出文档',
        type: 'warning',
        duration: 5000
      })
    },
    select (val) {
      this.selectIds = val.map(item => item.id)
    },
    selectAll (val) {
      this.selectIds = val.map(item => item.id)
    },
    statusChange (val) {
      this.status = val
    },
    salesAreaChange (salesArea, level, cityId) {
      this.salesArea = salesArea
      this.level = level
      this.cityId = cityId
    },
    codeBlur (val) {
      this.code = val
    },
    orderCodeBlur (val) {
      this.orderCode = val
    },
    repairCodeBlur (val) {
      this.repairCode = val ? +val : null
    },
    mobileBlur (val) {
      this.mobile = val
    },
    repairContacterBlur (val) {
      this.repairContacter = val
    },
    registrantContacterMobileBlur (val) {
      this.registrantContacterMobile = val
    },
    // storeIdChange (val) {
    //   this.storeId = val
    // },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    sourceChange (val) {
      this.source = val
    },
    remoteIdChange (val) {
      this.remoteId = val
    },
    serviceIdChange (val) {
      this.serviceId = val
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    secendOrderChange (val) {
      this.secendOrder = val
    },
    premiumProcessingChange (val) {
      this.premiumProcessing = val
    },
    poorUnpackingChange (val) {
      this.poorUnpacking = val
    },
    complaintChange (val) {
      this.complaint = val
    },
    urgeChange (val) {
      this.urge = val
    },
    responsePrescriptionChange (val) {
      this.responsePrescription = val
    },
    handlePrescriptionChange (val) {
      this.handlePrescription = val
    },
    visitPrescriptionChange (val) {
      this.visitPrescription = val
    },
    assignServiceChange (val) {
      this.assignService = val
    },
    visitServiceIdChange (val) {
      this.visitServiceId = val
    },
    dispatchIdChange (val) {
      this.dispatchId = val
    },
    ownershipResponsibilityChange (val) {
      this.ownershipResponsibility = val
    },
    treatmentMethodChange (val) {
      this.treatmentMethod = val
    },
    orderTypeChange (val) {
      this.orderType = val
      this.orderTypeChi = ''
      if (val) {
        this.orderTypeChiList = this['orderTypeChiList' + val]
      } else {
        this.orderTypeChiList = []
      }
    },
    orderTypeChiChange (val) {
      this.orderTypeChi = val
    },
    guestSentimentChange (val) {
      this.guestSentiment = val
    },
    // 领取
    receive (row) {
      // 10：待客服领取，20：待售后领取，30：待商家领取，40：待调度领取，50：客服跟进中，60：售后处理中，70：商家处理中，80：调度处理中，90：处理完成，100：待客服回访，1000：已完结
      let newStatus
      switch (row.status) {
        case 10:
          newStatus = 50
          break
        case 20:
          newStatus = 60
          break
        case 30:
          newStatus = 70
          break
        case 40:
          newStatus = 80
          break
        default:
          break
      }
      const params = {
        id: row.id,
        status: newStatus
      }
      workOrderUpdateStatus(params).then(res => {
        this.$message.success('领取成功')
        this.getList()
      })
    },
    // 置顶和取消置顶请求
    topRequestFn (orderId, top) {
      workOrderTop({ orderId, top }).then(_ => {
        this.$message.success(top ? '置顶成功' : '取消置顶成功')
        this.getList()
      })
    },
    top (row) {
      const params = {
        orderId: row.id,
        top: !row.top
      }
      workOrderTop(params).then(_ => {
        this.$message.success(row.top ? '取消置顶成功' : '置顶成功')
        this.getList()
      })
      // this.topRequestFn(row.id, true)
    },
    cancelTop (row) {
      this.topRequestFn(row.id, false)
    },
    // 跟进工单取消状态有删除操作
    over (row) {
      this.id = row.id
      this.hintShow = true
      this.hintCon = '删除工单'
    },
    sureOver () {
      if (this.hintCon === '删除工单') {
        // 10001：取消，10002：删除
        workOrderCancel({ id: this.id, status: 10002 }).then(res => {
          this.$message.success('删除成功')
          this.close()
        })
      } else {
        // 批量无需回访提示
        batchNoVisit({ orderIdList: this.selectIds }).then(_ => {
          this.$message.success('批量无需回访成功')
          this.close()
        })
      }
    },
    // 历史工单恢复操作
    restore (info) {
      this.info = info
      this.restoreShow = true
    },
    // 客户回访页面领取
    receiverToVist (row) {
      receiverToVist({ orderId: row.id }).then(res => {
        this.$message.success('领取成功')
        this.getList()
      })
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    close () {
      this.selectIds = []
      this.batchVisitShow = false
      this.hintShow = false
      this.restoreShow = false
      this.secondSceneShow = false
      this.SET_DRAWER_SHOW(false)
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.status = this.pageType === 'history' ? 1000 : ''
      this.code = ''
      this.orderCode = ''
      this.repairCode = null
      this.mobile = ''
      this.repairContacter = ''
      this.registrantContacterMobile = ''
      this.storeId = ''
      this.storeName = ''
      this.remoteId = ''
      this.serviceId = ''
      this.startTime = ''
      this.endTime = ''
      this.dispatchId = ''
      this.poorUnpacking = null
      this.premiumProcessing = null
      this.secendOrder = null
      this.responsePrescription = null
      this.handlePrescription = null
      this.visitPrescription = null
      this.assignService = null
      this.visitServiceId = ''
      this.complaint = null
      this.urge = null
      this.date = []
      this.ownershipResponsibility = ''
      this.treatmentMethod = ''
      this.orderTypeChiList = []
      this.orderType = ''
      this.orderTypeChi = ''
      this.guestSentiment = ''
      this.source = ''
      // 重置后赋值初始值
      this.level = this.initLevel
      this.cityId = this.initCityId
      this.salesArea = this.initSalesArea
      this.getList()
      this.getSelectData(101024, 'ownershipResponsibilityList')
      this.getSelectData(101021, 'treatmentMethodList')
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
