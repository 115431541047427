<template>
  <dialogComp title="恢复" :isShow="isShow" :modal="false" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <selectFormComp
        label="部门:"
        prop="status"
        placeholder="请选择部门"
        :data="statusList"
        :hval="formData.status"
        @change="statusChange"
      />
      <el-form-item label="接收人:" prop="userId">
        <el-select
          filterable
          v-model="formData.userId"
          clearable
          placeholder="请选择接收人"
          size="small"
           @change="userIdChange"
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.realName"
            :value="businessRole === 'store'?item.userId:item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <inputFormComp
        inputType="textarea"
        :hval="formData.remark"
        label="备注:"
        prop="remark"
        placeholder="请输入备注"
        @blur="remarkBlur"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import { userList, supplierUserList, overOrderRestore } from '@/api'
export default {
  components: {
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      businessRole: '',
      userList: [],
      formData: {
        status: '',
        userId: '',
        remark: ''
      },
      // 10：待客服领取，20：待售后领取，30：待商家领取，40：待调度领取
      statusList: [
        { label: '待客服领取', value: 10 },
        { label: '待售后领取', value: 20 },
        { label: '待商家领取', value: 30 },
        { label: '待调度领取', value: 40 }
      ],
      rules: {
        status: [{ required: true, message: '请选择部门', trigger: 'change' }],
        userId: [
          { required: true, message: '请选择转派人员', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    // 获取接收人列表
    getList (businessRole) {
      const { serviceId, remoteId, storeHandleId, dispatchId, storeId } = this.info
      // 商家处理人特殊接口
      if (businessRole === 'store') {
        const params = {
          curPage: 1,
          pageSize: 100,
          storeId
        }
        supplierUserList(params).then(res => {
          this.userList = res.data.list
          const userIdArr = this.userList.map(item => item.userId)
          // 如果找不到或者是等于0的就不显示
          if (!userIdArr.includes(storeHandleId) || storeHandleId === '0') {
            this.formData.userId = ''
          } else {
            this.formData.userId = storeHandleId
          }
        })
        // 没有选择商家就不往下执行
        return
      }
      const params = {
        curPage: 1,
        pageSize: 100,
        status: 1,
        workStatus: 1,
        businessRole
      }
      userList(params).then(res => {
        this.userList = res.data.list
        switch (this.formData.status) {
          case 10:
            this.formData.userId = serviceId === '0' ? '' : serviceId
            break
          case 20:
            this.formData.userId = remoteId === '0' ? '' : remoteId
            break
            // 商家用另一个接口
            // case 30:
            //   this.formData.userId = storeHandleId === '0' ? '' : storeHandleId
            //   break
          case 40:
            this.formData.userId = dispatchId === '0' ? '' : dispatchId
            break
          default:
            break
        }
      })
    },
    statusChange (val) {
      this.formData.status = val
      // 根据选择的哪个状态  获取当前的业务角色 方便获取接收人列表
      switch (val) {
        case 10:
          this.businessRole = 'service'
          break
        case 20:
          this.businessRole = 'aftermarket'
          break
        case 30:
          this.businessRole = 'store'
          break
        case 40:
          this.businessRole = 'dispatch'
          break
        default:
          break
      }
      this.getList(this.businessRole)
      this.formData.userId = ''
    },
    userIdChange (val) {
      this.formData.userId = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            id: this.info.id
          }
          overOrderRestore(params).then(res => {
            this.$message.success('恢复成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>
